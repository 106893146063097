import {
  FREEMIUM_PRICE_ID,
  MYCAP_PREMIUM_PLUS_PRICE_ID,
  MYCAP_PREMIUM_PRICE_ID_YEARLY,
  MYCAP_PREMIUM_RENEWAL_PRICE_ID,
  EFC_LABEL,
} from "@utils/constants";

export const plans = {
  student: {
    planName: "MyCAP",
    priceHeader: "Free",
    priceSubTag: (
      <ul className="header-list">
        <li>
          <span>Cancel anytime</span>
        </li>
      </ul>
    ),
    features: [
      `Instant ${EFC_LABEL} calculator`,
      "Instant Net Price Calculator: Up to 3 schools",
      "Access to every scholarship: Up to 3 schools",
      "Affordability Profile: Up to 3  schools",
      "Compare Offers & Appeal: Unlimited",
      "Line-by-line FAFSA & CSS/PROFILE Guidance",
      "CAP Community: Ask an expert live!",
      "CAP Academy: On-Demand Coaching",
      "Bi-Weekly, Live Office Hours",
    ],
    planPrices: [
      {
        label: "Free",
        value: 0,
        paymentType: "annually",
        type: "Freemium",
        dataCbItem: FREEMIUM_PRICE_ID,
      },
    ],
  },
  scholar: {
    planName: "MyCAP Premium",
    priceHeader: "$149/year",
    priceSubTag: (
      <ul className="header-list">
        <li>
          <span>$4.99 per month after first year</span>
        </li>
      </ul>
    ),
    features: [
      `Instant ${EFC_LABEL} calculator`,
      "Instant Net Price Calculator: Unlimited",
      "Access to every scholarship: Unlimited",
      "Affordability Profile: Unlimited",
      "Compare Offers & Appeal: Unlimited",
      "Sort by max financial aid %: Unlimited",
      "Sort by max scholarship $: Unlimited",
      "$5.1 Billion Private Scholarship Search",
      "Line-by-line FAFSA & CSS/PROFILE Guidance",
      "CAP Community: Ask an expert live!",
      "CAP Academy: On-Demand Coaching",
      "Bi-Weekly, Live Office Hours",
    ],
    planPrices: [
      {
        label: "one-time",
        value: 149,
        paymentType: "annually",
        type: "Scholar",
        dataCbItem: MYCAP_PREMIUM_PRICE_ID_YEARLY,
      },
    ],
  },
  valedictorian: {
    planName: "Premium + Consult",
    priceHeader: "$349/year",
    priceSubTag: (
      <ul className="header-list">
        <li>
          <span>MyCAP Premium and expert consult</span>
        </li>
      </ul>
    ),
    features: [
      "1-year subscription to MyCAP Premium",
      "1 Hour, 1:1 Meeting w/ CAP expert",
      "$99 savings by booking together!",
      <div>
        <div className="mb-1.5">
          Consultation can be used at any time during your college search
          process to discuss:
        </div>
        {[
          "Maximizing financial aid & scholarships",
          "School Recommendations",
          "FAFSA & CSS/PROFILE",
          "Negotiating with colleges",
          "How to pay your bill for all 4 years",
          "What loan/s to utilize for college",
          "Anything & Everything Else!",
        ].map((feature, key) => (
          <li key={key} className="micro">
            {feature}
          </li>
        ))}
      </div>,
    ],
    planPrices: [
      {
        label: "",
        value: 349,
        paymentType: "annually",
        type: "Valedictorian",
        dataCbItem: MYCAP_PREMIUM_PLUS_PRICE_ID,
      },
    ],
  },
  downgrade: {
    planName: "Limited Access",
    priceHeader: "$0/month",
    priceSubTag: "When downgrading, you keep:",
    features: [
      `Instant ${EFC_LABEL} calculator`,
      "Personal profile inputs",
      "Compare Offers & Appeal: Unlimited",
      "Line-by-line FAFSA & CSS/PROFILE Guidance",
      "CAP Community: Ask an expert live!",
      "CAP Academy: On-Demand Coaching",
      "Bi-Weekly, Live Office Hours",
    ],
    downgradeSubTag: "You will lose, however:",
    lostFeatures: [
      "Your existing school list",
      "Unlimited school analysis",
      "Access to the Advanced Search",
      "Access to Private Scholarships",
    ],
    planPrices: [
      {
        label: "Free",
        value: 0,
        paymentType: "annually",
        type: "Freemium",
        dataCbItem: FREEMIUM_PRICE_ID,
      },
    ],
  },
  renew: {
    planName: "Premium Access",
    priceHeader: "$4.99/month",
    priceSubTag: "Renew to keep all features:",
    features: [
      `Instant ${EFC_LABEL} calculator`,
      "Instant Net Price Calculator: Unlimited",
      "Access to every scholarship: Unlimited",
      "Affordability Profile: Unlimited",
      "Compare Offers & Appeal: Unlimited",
      "Sort by max financial aid %: Unlimited",
      "Sort by max scholarship $: Unlimited",
      "$5.1 Billion Private Scholarship Search",
      "Line-by-line FAFSA & CSS/PROFILE Guidance",
      "CAP Community: Ask an expert live!",
      "CAP Academy: On-Demand Coaching",
      "Bi-Weekly, Live Office Hours",
    ],
    planPrices: [
      {
        label: "monthly",
        value: 4.99,
        paymentType: "monthly",
        type: "Scholar",
        dataCbItem: MYCAP_PREMIUM_RENEWAL_PRICE_ID,
      },
    ],
  },
};
