import Image from "next/legacy/image";

import { Card } from "@components/index";
import { openExternalLink } from "@utils/externalLink";
import { useAppContext } from "@context/state";
import { PREMIUM_VIEW } from "@utils/constants";

/**
 * Sso card. Used for FAFSA and CSS cards. Both link to sso academy site.
 */
const SsoCard = ({ logo, description, url, logoWidth, logoHeight }) => {
  const { upgraded, setModalView, setDisplayModal } = useAppContext();

  /**
   * @param {object} link - {string} label, {boolean} redirect, {boolean} premium, {string} path
   */
  const onLinkClicked = (link) => {
    if (!upgraded) {
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      openExternalLink(link);
    }
  };

  const onImageClicked = (link) => {
    if (!upgraded) {
      setModalView(PREMIUM_VIEW);
      setDisplayModal(true);
    } else {
      openExternalLink(link);
    }
  }

  return (
    <div className="cardContainer">
      <Card type="typeTall">
        <div className="flex flex-col justify-between h-full">
          <div className="topHeading h-full">
            <div
              className="flex flex-row justify-between items-start cursor-pointer h-full"
              onClick={() => onImageClicked(url)}
            >
              <div className="fafsa-css-logo flex items-center">
                <Image
                  src={logo}
                  layout="responsive"
                  width={logoWidth}
                  height={logoHeight}
                  objectFit="contain"
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="divider" />
            <div className="mb-3 font-demi-bold">{description}</div>
            <a
              target="_blank"
              rel="noreferrer noopener"
              className="small font-demi-bold"
              onClick={() => {
                onLinkClicked(url);
              }}
            >
              Click here
            </a>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SsoCard;
